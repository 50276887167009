@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/SourceSansPro-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 400;
  src: url('../assets/fonts/SourceSansPro-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  src: url('../assets/fonts/SourceSansPro-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 600;
  src: url('../assets/fonts/SourceSansPro-SemiBoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  src: url('../assets/fonts/SourceSansPro-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 700;
  src: url('../assets/fonts/SourceSansPro-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 300;
  src: url('../assets/fonts/SourceSansPro-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'URW DIN Cond';
  font-style: normal;
  font-weight: 600;
  font-display: block;
  src: url('../assets/fonts/URWDINCond-Demi.ttf') format('truetype');
}

* {
  transition-duration: 0.25s;
  transition-property: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.svg-icon-wrapper {
  font-size: 0;
  display: block;
  height: 100%;
}

.svg-icon-wrapper svg * {
  transition: all 0.25s;
}

@supports selector(::-webkit-scrollbar) {
  ::-webkit-scrollbar {
    background: transparent;
    width: 6px;
    height: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background: #c9ceda;
    border-radius: 3px;
  }

  div[data-id='sidebar-scroll-wrapper'] {
    margin-right: 5px;
    padding-right: 11px;
  }

  div[data-id='sidebar-scroll-wrapper']::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.5);
  }
}

@supports not selector(::-webkit-scrollbar) {
  html {
    scrollbar-width: auto;
    scrollbar-color: #c9ceda transparent;
  }

  div[data-id='sidebar-scroll-wrapper'] {
    scrollbar-width: auto;
    scrollbar-color: rgba(255, 255, 255, 0.5) transparent;
  }
}
